import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {BookmarkService} from "./bookmark.service";
import {Observable, Subject, takeUntil} from "rxjs";
import {Bookmark} from "./bookmark.model";

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit, OnDestroy{


  @Input()
  open: boolean = false;
  @Output()
  openChange = new EventEmitter<boolean>();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router, private bookmarkService: BookmarkService) {
  }


  ngOnInit(): void {

    this.bookmarkService.onShowBookmarks().pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.open = value;
    })
  }


  get $bookmarks(): Observable<Bookmark[]>{
    return this.bookmarkService.getBookmarks();
  }

  navigate(bookmark: Bookmark){
    this.router.navigate(['/book/'+ bookmark.webId], { queryParams: {y: bookmark.offset}});
    window.setTimeout(()=>location.reload(), 50);
  }

  close(){
    this.open = false;
    this.openChange.emit(this.open);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
