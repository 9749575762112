
<div lang="de" class="page" [ngClass]="{'no-border': border == false, 'fitScreenSize': fitScreenSize}" *ngIf="content">
  <div style="width: 100%;" [innerHTML]="content"></div>
  <div class="number">{{number}}</div>
</div>

<div lang="de" class="page" *ngIf="!content" [ngClass]="{'no-border': border == false, 'fitScreenSize': fitScreenSize}">
  <ng-content></ng-content>
</div>

