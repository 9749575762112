<div style="width: 100%;">
  <div style="width:300px; margin: 0 auto; margin-top: 30px">
    <div style="padding:15px">
      <input id="username" type="text" [(ngModel)]="username" style="border: none; padding: 9px; outline: none">
    </div>
    <div style="padding:15px">
      <input id="password" type="password" [(ngModel)]="password" style="border: none; padding: 9px; outline: none">
    </div>
    <div>
      <button (click)="submit()"><i class="fas fa-key"></i></button>
    </div>

  </div>
</div>
