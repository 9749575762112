<app-navbar [bookId]="currentBookId"></app-navbar>
<div id="catalog" class="catalog" style="margin-top: 18px;">
    <swiper
      [config]="config"
      [allowSlidePrev]="showHistory"
      [virtual]="true"
      (swiper)="onSwiper($event)"
      (slideChange)="onSlideChange($event)"
      #swiper>
      <ng-template swiperSlide *ngFor="let slide of slides; index as i" #books>
        <app-landing-page *ngIf="getBookId(i) == 'INDEX'" [firstBook]="this.nextBookId"></app-landing-page>
        <app-book [id]="getBookId(i)" [showCoverOnly]="currentBookId != getBookId(i)" *ngIf="getBookId(i) != null && getBookId(i) != 'INDEX'"></app-book>
      </ng-template>
    </swiper>
 <div style="width:100%"><a class="imprint" href="/imprint" target="_blank">Impressum | AGB</a></div>
  <div>
  </div>

  <div class="action-button-row">
    <button id="get-last-button" [class]="{'disabled': isHistoryDisabled()}" (click)="last()" title="{{this.lastBookLinkTitle}}"><i class="fas fa-undo-alt"></i></button>
   <!-- get-next-link used instead <button id="get-next-button" class="big" (click)="next()" title="nächstes"><i class="fas fa-play"></i></button>-->
    <a id="get-next-link" class="link-button big" [href]="'/book/' + nextBookId" (click)="linkNext($event)" title="{{this.nextBookLinkTitle}}" ><i class="fas fa-play"></i></a>
    <button id="bookmark-button" (click)="bookmark()" class="big"><i class="fas fa-heart" title="{{this.bookmarkLinkTitle}}"></i></button>
    <button id="buy-button" title="bestellen" (click)="shop()" title="{{this.buyLinkTitle}}"><i class="fas fa-shopping-cart"></i></button>
    <!--<button id="share-button"><i class="fas fa-share-alt"></i></button>-->
  </div>
  <app-bookmarks></app-bookmarks>


  <button id="jump-bookmark-button" [class]="{'opacity-1': showJumpToBookmarkButton()}" *ngIf="showJumpToBookmarkButton()" (click)="jumpToBookmark()" title="Zum Lesezeichen springen"><i class="fas fa-bookmark"></i></button>
</div>
