import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthService{

  authentication: any;

  constructor(private httpClient: HttpClient) {
    if (!this.authentication){
      this.authenticate();
    }
  }

  authenticate(){
    this.httpClient.get('/api/v1/auth', {observe: 'response'}).subscribe(response => {

      if (response.status === 200){
        const bearer= response.headers.get('Authorization');
        if(bearer){
          this.parseToken( bearer.replace('Bearer ', ''));
        }
      }
    },
      error => console.log('error', error));
  }

  parseToken(token: string){
    if(token){
      const parts = token.split('.');
      this.authentication = JSON.parse(atob(parts[1]));
    }

  }

}
