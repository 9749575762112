import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent {

  @Input()
  content: string = '';

  @Input()
  initialized = false;

  @Output()
  contentChange: EventEmitter<string> = new EventEmitter<string>();


  initEditor(event: any){
    if(event && event.root){
      event.root.innerHTML = this.content;
    }
  }
  onEditorChange(content: any){
    if(this.initialized){
      this.contentChange.emit(content.html);
    }
  }
}
