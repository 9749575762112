<div class="lp">

  <h1>Finde dein nächstes Buch.</h1>
  <div class="panel-board">
    <div class="panel">
      <div class="header pink">
        <h2 class="">Du suchst ein neues Buch, welches du als nächstes lesen kannst?</h2>
      </div>
      <div class="body">
        <p>Dann bist du genau richtig auf Chapter One: Swipe durch Romane, Thriller, Liebesgeschichten, Fantasy, Science Fiction, Abenteuer und weitere tolle Geschichten und finde dein nächstes Buch - Match.</p>
        <p>Swipe nach links oder drücke den <i style="color: #00f5ff" class="fas fa-play"></i> Button, um direkt loszulegen!</p>
      </div>
    </div>
    <div class="panel">
      <div class="header blue">
        <h2 class="">Buch Empfehlung des Tages</h2>
      </div>
      <div class="body reco" (click)="goReco()">
        <img [src]="'/api/v1/book/cover/' + firstBook" *ngIf="firstBook" style="width: 100%; height: 100%">
      </div>
    </div>
    <div class="panel">
      <div class="header gold">
        <h2 class="">Kann ich mir ein Buch für später merken?</h2>
      </div>
      <div class="body">
        <p>Wenn du ein Buch gefunden hast, das dir gefällt, dann speicher es. Setze ein Lesezeichen, indem du auf das <i style="color: #ff00b0;" class="fas fa-heart"></i> Symbol drückst. Dein Buch wird jetzt unter den Lesezeichen in der Navigation gespeichert.
        <p>Außerdem wird deine aktuelle Leseposition gespeichert.</p>
        <p>Öffne deine Lesezeichen in dem du auf das  <i style="color: gold;" class="fas fa-layer-group"></i> Symbol drückst.</p>
        <p>Wenn dir das Buch gefällt, dann freuen wir uns, wenn du uns unterstüzt indem du den <i style="color: #2bcbf2" class="fas fa-shopping-cart"></i> drückst.</p>
      </div>
    </div>
    <div class="panel"></div>
  </div>
</div>

