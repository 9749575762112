import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BookService} from "../book/book.service";
import {Book} from "../book/book.model";
import {take} from "rxjs";
import {BookmarkService} from "./bookmark.service";
import {Bookmark} from "./bookmark.model";

@Component({
  selector: 'app-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss']
})
export class BookmarkComponent implements OnInit, OnChanges{

  @Input() bookmark: Bookmark;

  authorFirstName: string = '';
  authorLastName: string = '';
  genre: string = '';
  isbn: string = '';
  publisher: string = '';
  status: string = '';
  title: string = '';
  authors: any = [];

  constructor(private bookService: BookService, private bookmarkService: BookmarkService) {
  }


  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes['bookmark'] && changes['bookmark'].currentValue){
      this.bookService.getInfo(this.bookmark.webId).pipe(take(1)).subscribe(info => {
        if(info){
          this.isbn = info.isbn;
          this.title = info.title;
          this.genre = info.genre;
          if(info.authors.length){
            this.authors = info.authors;
            this.authorFirstName = info.authors[0].firstName;
            this.authorLastName = info.authors[0].lastName;
          }
          this.status = info.status;
          this.publisher = info.publisher;
        }
      });
    }
  }

  shop(bookId: string | null){
    if(bookId){
      this.bookService.get(bookId).subscribe((book: Book) => {
        if(book){
          window.open(book.amzLink,'_blank');
        }
      });
    }
  }

  removeBookmark($event: Event, bookmark: string){
    this.bookmarkService.deleteBookmark(bookmark);
    $event.preventDefault();
    $event.stopPropagation();
  }

}
