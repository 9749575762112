import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {AuthService} from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient, private router: Router, private authService: AuthService) { }


  submit(credentials: {username: string, password:string}) {
    const params = new HttpParams().set('username', credentials.username).set('password', credentials.password);
    this.httpClient.post('/api/login', null, {params: params,  observe: 'response'}).subscribe(response => {

      if (response.status === 200){
        const bearer= response.headers.get('Authorization');
        if(bearer){
          this.authService.parseToken( bearer.replace('Bearer ', ''));
        }
        this.router.navigate(['/']);
      }
    });
  }
}
