
<div class="bookmarks-container" [class]="{'open': open}">
  <div style="width: 100%; height: 50px; position: relative">
    <button style="position: absolute; right: 0;" (click)="close()"><i class="fas fa-arrow-right"></i></button>
  </div>
  <div id="bookmark-list-container" style="max-height: calc(100vh - 50px); overflow-y: auto;">
  <ul id="bookmark-list" style="padding-top: 12px">
    <li *ngFor="let bookmark of ($bookmarks | async)" (click)="navigate(bookmark)">
      <app-bookmark [bookmark]="bookmark"></app-bookmark>
    </li>
  </ul>
  </div>
</div>
