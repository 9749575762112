import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
 // encapsulation: ViewEncapsulation.None
})
export class PageComponent {

  @Input()
  number: number = 1;

  @Input()
  content: string = '';

  @Input() border: boolean = true;
  @Input() fitScreenSize: boolean = false;

  /*
  Was er alles tat für nichts und wieder nichts.

Tom stand vor dem Spiegel und band sich die Krawatte. Inzwischen hatte er ein wenig Übung darin, nach so vielen Vorstellungsgesprächen. Am Anfang trug er keine, er suchte ja keinen Krawattenjob. Seine Abschlussnoten waren Krawatte genug, fand er. Doch inzwischen hatte er die Milch heruntergegeben, wie sein Vater sich auszudrücken pflegte. Tom hatte nie herausgefunden, woher die Redensart kam. Aber was sie bedeutete, war ihm schon klar: vom hohen Ross herunterkommen.

Jetzt war er unten. Mit Krawatte.

Tom besaß ein Double Degree. Zwei Master of Law, einen der hiesigen Uni und einen des King’s College London. Für Letzteren hatte er zwei Studienjahre angehängt, weil er seinen Abschluss hatte hinauszögern wollen. Sein Vater bezahlte ihm das Studium, und Tom hatte keinen Grund und keine große Lust, berufstätig zu werden.

Ursprünglich hatte er geplant, nach New York zu gehen und dort auch noch das Bar Exam abzuschließen. Doch kurz vor der Abreise nahm sich sein wohlhabender Vater das Leben. Es stellte sich heraus, dass der Grund dafür eine ausweglose Verschuldung war. Auf seine Mutter, die seit ihrer Scheidung mit einem


   */
}
