import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BookmarkService} from "../../bookmarks/bookmark.service";
import {take} from "rxjs";
import {BookService} from "../../book/book.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnChanges{

  @Input() bookId?: string;
  showBookmarks = false;
  slogan = 'Match your next book';
  bookInfo = {
    title: '',
    authors: [],
    genre: '',
    isbn: ''
  };

  constructor(private bookService: BookService, public bookmarkService: BookmarkService) {
  }
  get bookmarkCount(): number {
    return this.bookmarkService.count;
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes['bookId'] && changes['bookId'].currentValue){

        this.bookService.getInfo(this.bookId!!).pipe(take(1)).subscribe(info => {
          if(info){
            this.bookInfo.isbn = info.isbn;
            this.bookInfo.title = info.title;
            this.bookInfo.genre = info.genre;
            this.bookInfo.authors = info.authors.map((x:{lastName: string, firstName: string}) => x.firstName + ' ' + x.lastName);
          }
        });

    }
  }
  setShowBookmarks(value: boolean){
    this.bookmarkService.toggleShowBookmarks();
  }

  ngOnInit(): void {
    this.bookmarkService.onShowBookmarks().subscribe(value => {
      this.showBookmarks = value;
    });
  }
}
