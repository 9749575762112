<div style="background-color: white; padding: 15px; height: 100%;">

<div style="width: 100%; max-width: 512px">
Inhaltlich verantwortlich gemäß §5 TMG:<br>
  <div>

<img src="/assets/img/name.png" style="transform: scale(0.75);"><br><br>
  </div>
  Anschrift:
  <div>

  <img src="/assets/img/contact.png" style="transform: scale(0.75);"><br><br>
  </div>
  E-Mail:
  <div>
  <img src="/assets/img/mail.png"><br><br>
  </div>
</div>
<div  style="width: 100%; max-width: 512px">
Rechtliche Hinweise<br><br>

  Haftungsausschluss<br><br>
  <div style="margin-bottom: 15px">
    Haftung für Inhalte <br><br>
    Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte übernehmen der Betreiber der Webseite jedoch keine Gewähr. Als Diensteanbieter gemäß §7 Abs.1 TMG ist der Betreiber für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach § 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, Übermittelte oder gespeicherte fremde Informationen zu Überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werde wir diese Inhalte umgehend entfernen.
  </div>
  <div style="margin-bottom: 15px">
    Haftung für Links<br><br>
    Das Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss habe. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werde wir derartige Links umgehend entfernen.
  </div>
  <div style="margin-bottom: 15px">
    Urheberrecht<br><br>
    Die durch den Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werde wir derartige Inhalte umgehend entfernen.
    <br><br>
    Das Eigentum der abgeblideten Werke, deren Cover Illustrationen und Texte liegt bei den dabei genannten Verlagen und / oder Autoren und sind ebenfalls urheberrechtlich geschützt und dürfen ohne vorherige schriftliche Zustimmung nicht für öffentliche oder gewerbliche Zwecke vervielfältigt, verändert, übertragen, wiederverwendet, neu bereitgestellt, verwertet oder auf sonstige Weise benutzt werden.
  </div>
  <div style="margin-bottom: 15px">
    Datenschutz<br><br>
    Die Nutzung der Webseite ist ohne Angabe personenbezogener Daten möglich. Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Der Betreiber der Seiten behält sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
  </div>
</div>

</div>
