import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CatalogService{

 // private catalog: string[] = [];
  private catalog$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  constructor(private httpClient: HttpClient) { }

  refreshList(){
  // genre?: string, author?: string
    // should come from http call, but we make a mock
    return [this.makeid(), this.makeid(), this.makeid(), this.makeid(), this.makeid()];
  }


  get catalog(): Observable<string[]> {
    return this.catalog$.asObservable();
  }

  loadCatalog(genre: string, index: number){
    genre = genre === 'BOOK' ? '' : genre;
    this.httpClient.get('/api/v1/catalog?index=' + index + '&genre=' + genre).subscribe((response: any) => {
      if(response){
        this.catalog$.next(response);
      }
    });
  }



   makeid() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 6) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
}
