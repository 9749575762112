import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {BookComponent} from "./book/book.component";
import {CatalogComponent} from "./catalog/catalog.component";
import {LoginComponent} from "./login/login.component";
import {ImprintComponent} from "./legal/imprint.component";


const routes: Routes = [
  {
    path: '',
    component: CatalogComponent,
  },
  { path: 'edit/:id', component: BookComponent },
  { path: 'create', component: BookComponent },
  {
    matcher: (url) => {
        return /^book|novel|crime|erotic|historic|horror|romance|mystery|fantasy|thriller$/.test(url[0].path) ?  ({consumed: url}) : null;
    },
    component: CatalogComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'imprint',
    component: ImprintComponent,
  },
];


@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})



export class AppRoutingModule { }
