import {inject, Injectable} from '@angular/core';
import {Book} from "../book/book.model";
import {AuthorSchema, BookSchema} from "./schema.model";
import {DOCUMENT, formatDate} from "@angular/common";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SchemaService {

  private readonly document = inject(DOCUMENT);
  private jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Chapter One",
    "alternateName": ["ChapterOne", "chapter one", "chapter 1", "chptr1", "chapter.one", "C1"],
    "url": "https://chapter.one/",
    "mainEntity": {}
  }

  private schema$ = new BehaviorSubject<any>(this.jsonLd);

  constructor() {
    this.initSchema();
  }



  updateSchema(book: Book){
    this.jsonLd.mainEntity = this.createBookSchema(book);
    this.inject();
  }

  private initSchema() {
    this.inject();
  }

  private createBookSchema(book: Book): BookSchema {
    let bookSchema: BookSchema = {
      "@context": "https://schema.org",
      "@type": "Book",
      "bookFormat": "https://schema.org/Paperback",
      name: book.title!!,
      genre: book.genre!!,
      inLanguage: "deutsch",
      isbn: book.isbn!!,
      image: '/api/v1/book/cover/' + book.webId,
      datePublished: formatDate(book.publishingDate!!, 'dd.MM.yyyy', 'de'),
      publisher: book.publisher!!,
      author: this.createAuthorSchema(book)
    };
    return  bookSchema;
  }
  private createAuthorSchema(book: any): AuthorSchema {
    let author: AuthorSchema = {
      "@context": "https://schema.org",
      "@type": "Person",
      name: book.authors[0].firstName + " " + book.authors[0].lastName
    };
    return author;
  }

  private inject(): void {
    let ldJsonScriptTag = this.document.head.querySelector(`script[type='application/ld+json']`);
    if (ldJsonScriptTag) {
      ldJsonScriptTag.textContent = JSON.stringify(this.jsonLd);
    } else {
      ldJsonScriptTag = this.document.createElement('script');
      ldJsonScriptTag.setAttribute('type', 'application/ld+json');
      ldJsonScriptTag.textContent = JSON.stringify(this.jsonLd);
      this.document.head.appendChild(ldJsonScriptTag);
    }
  }

}
