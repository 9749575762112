<div class="book">

  <button id="save" *ngIf="edit" class="save-button" (click)="saveBook()"
          style="position: fixed; right: 15px; top: 15px;"><i class="fas fa-save"></i></button>
  <ng-container *ngIf="edit">
    <app-page>
      <form [formGroup]="form">
        <div style="width: 100%">
          <input id="title" type="text" placeholder="Titel *" formControlName="title" style="width: 100%">
        </div>
        <div id="authors">
          <label>Authors</label>
          <ng-container formArrayName="authors">
            <ng-container *ngFor="let author of authors.controls; let i = index">
                <div [formGroupName]="i">
                  <input formControlName="firstName"
                         placeholder="" [readonly]="true">
                  <input
                         formControlName="lastName"
                         placeholder="" [readonly]="true">
                  <button id="remove-author" class="remove" (click)="removeAuthor(i)"><i class="fas fa-times"></i></button>
                </div>
              </ng-container>
            </ng-container>
          <div id="new-author" class="flex row">
            <div>
              <input id="authorFirstName" type="text" placeholder="Author Vorname *" formControlName="authorFirstName">
              <input id="authorLastName" type="text" placeholder="Author Nachname *" formControlName="authorLastName">
            </div>
            <div>
              <button id="add-author" class="add" (click)="addAuthor()"><i class="fas fa-plus"></i></button>
            </div>
          </div>
        </div>
        <div>
          <input id="isbn" type="text" placeholder="ISBN *" formControlName="isbn">
        </div>
        <div>
          <select id="genre" type="text" placeholder="Genre *" formControlName="genre">
            <option selected value="NOVEL">Roman</option>
            <option value="CRIME">THRILLER</option>
            <option value="CRIME">Krimi</option>
            <option value="ROMANCE">Liebe</option>
            <option value="MYSTERY">Mystery</option>
            <option value="FANTASY">Fantasy</option>
            <option value="SCIFI">Science Fiction</option>
            <option value="HORROR">Horror</option>
            <option value="EROTIC">Erotik</option>
            <option value="HISTORIC">Historisch</option>
          </select>
        </div>
        <div>
          <input id="publisher" type="text" placeholder="Verlag *" formControlName="publisher">
        </div>
        <div>
          <input id="publishingDate" type="date" placeholder="Erschienen am *" formControlName="publishingDate">
        </div>
        <div>
          <select id="language" placeholder="Sprache *" formControlName="language">
            <option selected value="DE">deutsch</option>
          </select>
        </div>
        <div>
          <input type="file" id="cover-image" (change)="onCoverImageChange($event)">
          <span *ngIf="showImageCheckmark" class="check-icon"> <i class="far fa-check-circle"></i></span>
        </div>
        <div style="width: 100%">
          <input id="amzlink" type="text" placeholder="Amazon Affilate Link" style="width: 100%"
                 formControlName="amzLink">
        </div>
        <div class="flex">
          <label id="label-enabled" for="status">Published</label>
          <input id="status" type="checkbox" formControlName="status" >
        </div>
      </form>
    </app-page>
    <app-page>
      <app-editor [content]="htmlContent" (contentChange)="onContentHtmlChange($event)"></app-editor>
    </app-page>
  </ng-container>

  <!-- display book -->

  <ng-container *ngIf="!edit && book">
    <a id="editLink" [href]="'/edit/' + book.webId" target="_blank"
       style="position: absolute; right: 15px; top: 15px; z-index: 1;" *ngIf="showEditButton">
      <button class="edit-button"><i class="fas fa-pen"></i></button>
    </a>
    <div style="width: 100%; margin: 0 auto; text-align: center"><h1 id="title-flow" class="book-title-flow">{{book.title}}</h1></div>
    <app-page id="cover" [border]="false" [fitScreenSize]="true" *ngIf="book.imageId">
      <div id="bookcover" style="width: 100%; height: auto; overflow: hidden">
        <!--      <input type="file" id="cover-image" formControlName="coverImage">-->
        <img [src]="'/api/v1/book/cover/' + id" style="width: 100%; height: 100%; display: block;">
      </div>
    </app-page>
    <div id="about-book">
      <span id="publisher">{{book.publisher}}</span>
      <span>-</span>
      <span id="publishingDate">{{book.publishingDate  | date}}</span>
    </div>


    <ng-container *ngFor="let pageContent of this.book?.content; index as i" >
      <app-page [content]="pageContent" [number]="i + 1"  [hidden]="showCoverOnly"></app-page>
    </ng-container>
  </ng-container>
</div>
