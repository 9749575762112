<nav details="">
  <div class="flex space-between align-center" style="gap:15px; height: 100%">

    <div id="nav-Book-info" style="padding-left: 15px;">

      <div class="authors" *ngIf="!this.bookId">
        <a href="/"><img src="/assets/svg/book.svg"></a>
        <span id="slogan" style="padding-left: 6px; font-weight: 600; color: #ff00b0; vertical-align: super;">{{this.slogan}}</span>
        </div>
      <div class="authors" *ngIf="this.bookId">
        <a href="/"><img src="/assets/svg/book.svg"></a>
        <ng-container *ngFor="let author of this.bookInfo.authors; let i = index">
          <span [id]="'nav-author-' + i" style="padding-left: 6px; vertical-align: super;"> {{author}}</span>
        </ng-container>
      </div>
    </div>

    <div style="padding-right: 15px;" class="flex row flex-end align-center">
      <div style="width: 95px;">
        <a  [href]="'/' + this.bookInfo.genre.toLowerCase()">
          <span id="nav-genre">{{this.bookInfo.genre}}</span>
        </a>
      </div>
      <button id="bookmarks" class="icon-ctr-button" (click)="setShowBookmarks(true);" title="Meine Lesezeichen">
        <i class="fas fa-chevron-right" style="font-size: 21px;" *ngIf="this.showBookmarks"></i>
        <i class="fas fa-layer-group" *ngIf="!this.showBookmarks"></i>
        <span class="counter"  *ngIf="!this.showBookmarks">{{this.bookmarkCount}}</span>
        <div id="bookmark_preview" *ngIf="bookId && (this.bookmarkService.animate | async)"
             [style]="'background-color: white; background-image: url(\'/api/v1/book/cover/' + bookId + '\');'"
             [class]="{'bookmark_animation': (this.bookmarkService.animate | async)}">
        </div>
      </button>
    </div>
  </div>
</nav>
