<div style="display: flex; justify-content: space-between">
  <div style="flex-grow: 1;">
    <div id="bookmarkTitle">{{title}}</div>
    <div id="bookmarkAuthor">
      <span id="bookmarkAuthorFirstName">{{authorFirstName}}</span><span id="bookmarkLastName">{{authorLastName}}</span>
    </div>
  </div>
  <div style="display: flex; gap: 6px">
    <button [id]="'shop-bookmark-' + bookmark" title="bestellen" (click)="shop(bookmark.webId)"><i class="fas fa-shopping-cart"></i></button>
    <button [id]="'remove-bookmark-' + bookmark" (click)="removeBookmark($event, bookmark.webId)" ><i class="fas fa-times" title="remove"></i></button>
  </div>
</div>
