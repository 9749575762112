import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BookService} from "../book/book.service";
import {LoginService} from "./login.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {


  username: string = '';
  password: string = '';

  constructor(private loginService: LoginService) {
  }


  submit(){
    console.log(this.username + '');
    this.loginService.submit({username: this.username, password: this.password});
  }
}
