import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {BookService} from "./book.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Book} from "./book.model";
import {AuthService} from "../auth/auth.service";
import {BehaviorSubject, debounceTime, Subject, take, takeUntil} from "rxjs";

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit, OnDestroy {
  @Input() id: any = '';
  @Input() showCoverOnly: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  htmlContent: string = '';

  edit = false;
  book: Book = {content: []};

  valid = false;
  private valid$: Subject<boolean> = new BehaviorSubject(this.valid);

  imageSrc?: string | ArrayBuffer = '';

  form: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    authors: new FormArray([]),
    authorFirstName: new FormControl(),
    authorLastName: new FormControl(),
    isbn: new FormControl('', [Validators.required]),
    genre: new FormControl('', [Validators.required]),
    publisher: new FormControl('', [Validators.required]),
    publishingDate: new FormControl('', [Validators.required]),
    language: new FormControl('', [Validators.required]),
    coverImage: new FormControl(null),
    amzLink: new FormControl('', [Validators.required]),
    status: new FormControl(''),
  });


  private ONE_MB: number = 1048576;
  private MAX_MB: number = 2 * this.ONE_MB;

  constructor(private route: ActivatedRoute, private router: Router, private bookService: BookService, private authService: AuthService) {
  }

  get showEditButton(): boolean{
   return this.isEditor();
  }

  get showImageCheckmark(): boolean{
    return this.book.imageId !== undefined;
}

  private isEditor(){
    return this.authService.authentication ? this.authService.authentication.sub === this.book.editor : false;
  }
  ngOnInit(): void {

    this.edit = this.router.url.includes('edit') || this.router.url.includes('create');
    this.form.controls["status"].disable();

    if (this.edit) {
     this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
        const bookId = params.get('id');
        if (bookId && this.id != bookId) {
          this.id = bookId;
          this.loadBook(this.id);
        }
      });

      this.valid$.pipe(takeUntil(this.destroy$)).subscribe(value => {
        if(this.valid !== value){
          this.valid = value;
          if(value){
            this.form.controls["status"].enable();
          }else{
            this.form.controls["status"].disable();
          }
        }
      });

      this.form.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(changes => {
        if(changes && this.form.touched){
          this.validate();
        }
      });

    } else {
      if (this.id && this.book?.id != this.id) {
        this.loadBook(this.id);
      }
    }
  }


  onContentHtmlChange(html: string) {
    if (html) {
      this.htmlContent = html;
      this.book.content = this.htmlToContent(html);
    }
  }

  loadBook(id: string) {
    this.bookService.get(id).subscribe(response => {
      if (response) {

        this.book = {
          ...response,
          publishingDate: this.mapPublishingDateResponse(response.publishingDate),
          content: this.htmlToContent(response.content)
        }

        if (this.edit) {

          if (!this.isEditor()) {
            this.router.navigate(['/']);
          } else {

            this.htmlContent = response.content;
            const formData = {
              ...response,
              status: response.status === 'true' ? true : '',
              authors: this.initAuthor(response.authors),
              publishingDate: this.mapPublishingDateResponse(response.publishingDate),
            }
            this.form.patchValue(formData);
          }
        }else {
          if(!this.showCoverOnly){
            this.setPageOffset();
          }
        }
      }
    });
  }

  onCoverImageChange(event: any): void {
    if (event.target?.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (file && file.size < this.MAX_MB) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageSrc = reader.result!!;
        };

        reader.readAsDataURL(file);

        this.bookService.uploadCoverImage(this.id, file).subscribe(response => {
          if (response) {
            this.loadBook(this.id);
          }
        });
      }
    }
  }

  get authors() {
    return this.form.controls['authors'] as FormArray;
  }
  addAuthor() {

    if (this.form.controls['authorFirstName'].getRawValue() && this.form.controls['authorLastName'].getRawValue()) {
      const authorForm = new FormGroup({
        firstName: new FormControl(),
        lastName: new FormControl()
      });
      authorForm.patchValue({
        firstName: this.form.controls['authorFirstName'].getRawValue(),
        lastName: this.form.controls['authorLastName'].getRawValue()
      });

      this.authors.push(authorForm);

      this.form.controls['authorFirstName'].setValue('');
      this.form.controls['authorLastName'].setValue('');
    }
  }

  private initAuthor(authors: any) {
    const authorsFormArray =  new FormArray([]);

    if (authors) {
      for(const author of authors) {
        let authorForm = new FormGroup({
          firstName: new FormControl(author.firstName),
          lastName: new FormControl(author.lastName)
        });

        this.authors.push(authorForm);
      }
    }
    return authors;
  }

  removeAuthor(i: number) {
    this.authors.removeAt(i);
  }

  saveBook() {
    const formData = this.form.getRawValue();
    const bookRequest = {
      id: this.book.id,
      title: formData.title,
      genre: formData.genre,
      authors: formData.authors,
      isbn: formData.isbn,
      publisher: formData.publisher,
      publishingDate: this.mapPublishingDateRequest(formData.publishingDate),
      image: formData.imageSrc,
      language: formData.language,
      amzLink: formData.amzLink,
      status: this.valid && formData.status,
      content: this.htmlContent
    }

    this.save(bookRequest);
  }

  private save(request: any) {
    if (this.id) {
      this.bookService.update(this.id, request).subscribe(response => {
        if (response.webId) {
          this.validate();
          console.log("updated");
        }
      });
    } else {
      this.bookService.save(request).subscribe(response => {
        if (response.webId) {
          this.router.navigate(['/edit/' + response.webId]);
        }
      });
    }
  }

  private htmlToContent(html: string): string[] {
    let content: string[] = [];
    if (html.indexOf('[\np]')) {
      const pages = html.split('[\\np]');
      for (let page of pages) {
        content.push(page.replace(/\s+/g, ' '));
      }
    } else {
      content = [html];
    }

    return content;
  }


  private mapPublishingDateRequest(request: string): string | undefined {
    if (request) {
      const date = Date.parse(request);
      return new Date(date).toISOString();
    }

    return;
  }

  private mapPublishingDateResponse(response: string): string | undefined {
    if (response) {
      try {
        new Date(response);
        const date = response.substring(0, 10);
        return date;
      } catch (e) {
        console.log('failed to format date ', e);
      }
    }
    return;
  }

  private validate(){
      this.valid$.next(this.form.valid
                && this.authors.length > 0
                && this.htmlContent.length > 1000
                && this.showImageCheckmark);
  }

  private setPageOffset(){
    this.route.queryParams.pipe(take(1)).subscribe(params =>{
      if(params['y'] && this.id === this.route.snapshot.url[1].toString()){
        setTimeout(function(){
          window.scroll({
            top: parseInt(params['y']),
            left: 0,
            behavior: 'smooth'
          });
        }, 50);
      }
    } );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}

/*  private contentToHtml(content: string[]): string {
    let html = '';
    if (content) {
      for (let i = 0; i < content.length; i++) {
        if (i < 0) {
          html += '[\np]';
        }
        html += content[i];
      }
    }
    return html;
  }*/
