import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {


  @Input() firstBook: string;

  constructor(private router: Router) {
  }

  goReco(){
    this.router.navigate(['book/' + this.firstBook]);
  }

}
