import { Injectable } from '@angular/core';
import {Book, BookInfo} from "./book.model";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, map, Observable, of, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BookService {

  private books:  { [webId:string]: Book } = {};
  private infos:  { [webId:string]: BookInfo } = {};

  constructor(private httpClient: HttpClient) { }


  save(book: any): Observable<any>{
    console.log(JSON.stringify(book));

    return this.httpClient.post('/api/v1/book', book);
  }

  update(id: string, book: any): Observable<any>{
    console.log(JSON.stringify(book));

    return this.httpClient.put('/api/v1/book/' + id, book);
  }

  validate(book: any){

  }

  uploadCoverImage(id: string, file: File): Observable<any>{
    const data: FormData = new FormData();
    data.append('file', file);
    return this.httpClient.put('/api/v1/book/cover/' + id, data);
  }

  get(bookId: string): Observable<any>{
    if(this.books[bookId]){
      return of(this.books[bookId]);
    }

    return this.httpClient.get<Book>('/api/v1/book/' + bookId).pipe(
      map((book: Book) => {
        this.books[bookId] = book;
        return book;
      })
    );
  }

  getInfo(bookId: string): Observable<any>{
    if(this.infos[bookId]){
      return of(this.infos[bookId]);
    }
    return this.httpClient.get<BookInfo>('/api/v1/book/' + bookId + '/info').pipe(
      map((book: BookInfo) => {
        this.infos[bookId] = book;
        return book;
      })
    );
  }


}
