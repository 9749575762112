import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {BookComponent} from './book/book.component';
import {PageComponent} from './book/page.component';
import {AppRoutingModule} from './app-routing.module';
import {QuillModule} from "ngx-quill";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EditorComponent} from './book/editor.component';
import {CommonModule, registerLocaleData} from "@angular/common";
import localeDE from '@angular/common/locales/de';
import {CatalogComponent} from './catalog/catalog.component';
import {SwiperModule} from "swiper/angular";
import {HttpClientModule} from "@angular/common/http";
import {BookmarksComponent} from './bookmarks/bookmarks.component';
import {BookmarkComponent} from './bookmarks/bookmark.component';
import {LoginComponent} from './login/login.component';
import {NavbarComponent} from './catalog/navi/navbar.component';
import { ImprintComponent } from './legal/imprint.component';
import { LandingPageComponent } from './landing/landing-page.component';

registerLocaleData(localeDE);

@NgModule({
  declarations: [
    AppComponent,
    BookComponent,
    PageComponent,
    EditorComponent,
    CatalogComponent,
    BookmarksComponent,
    BookmarkComponent,
    LoginComponent,
    NavbarComponent,
    ImprintComponent,
    LandingPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    QuillModule.forRoot({
      customOptions: [{
        import: 'formats/font',
        whitelist: ['mirza', 'roboto', 'aref', 'serif', 'sansserif', 'monospace']
      }]
    }),
],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: navigator.language,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
