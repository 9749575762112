import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';

import { Meta, Title } from '@angular/platform-browser';
// import Swiper core and required modules
import SwiperCore, {SwiperOptions, Virtual} from "swiper";
import {CatalogService} from "./catalog.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {BookmarkService} from "../bookmarks/bookmark.service";
import {Subject, take, takeUntil} from "rxjs";
import {BookService} from "../book/book.service";
import {Book} from "../book/book.model";
import { formatDate } from '@angular/common';
import {Bookmark} from "../bookmarks/bookmark.model";
import {AuthService} from "../auth/auth.service";
import {SchemaService} from "../schema/schema.service";

// install Swiper modules
SwiperCore.use([Virtual]);

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit, OnDestroy{

  swiper: any;

  bookList: string[] = [];
  private index =  0;
  private lastIndex = this.index;
  private initiated = false;
  private continueFromHistory = false;

  private scrollY = 0;
  showHistory = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  nextBookLinkTitle = 'Nächstes Buch anzeigen';
  lastBookLinkTitle =  'Vorheriges Buch anzeigen';
  bookmarkLinkTitle = 'Für dieses Buch ein Lesezeichen anlegen';
  buyLinkTitle = 'Dieses Buch kaufen';

  config: SwiperOptions = {
    initialSlide: this.index,
    slidesPerView: 1,
    spaceBetween: 50,

    navigation: true,
    centeredSlides: true,

    pagination: {clickable: true},
    scrollbar: {draggable: true},
  };

  slides = Array.from({ length: 1000 }).map(
    (el, index) => ''
  );

  genre: string;
  currentBookId?: string;
  currentBookmark: Bookmark | null;
  jumpBookmarkActive = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private catalogService: CatalogService,
    private bookService: BookService,
    private bookmarkService: BookmarkService,
    private titleService: Title,
    private metaService: Meta,
    private authService: AuthService,
    private schemaService: SchemaService
  ) {

  }


  @HostListener('window:scroll', ['$event'])
  recordScrolling(event: Event) {
    this.scrollY = window.scrollY;
    if(this.currentBookmark && this.scrollY > (this.currentBookmark.offset + 450)) {
      this.bookmarkService.updateBookmark(this.currentBookmark.webId, {offset: this.scrollY});
    }
  }


  ngOnInit(): void {

    this.setGenre(this.router.url);

    if(this.route.snapshot.url.length === 2){
      const bookId = this.route.snapshot.url[1].toString();
      this.setBookPageInfos(bookId);

      if(this.bookList.length === 0 && bookId) {
        this.currentBookId = bookId;
        this.bookList.push(bookId);
      }
    }else {
      this.bookList.push("INDEX");
    }

    this.catalogService.catalog.pipe().subscribe(catalog =>{
      if(catalog.length){

        if(this.bookList.length === 0) {
          const bookId = catalog[0];
          this.currentBookId = bookId;
          this.setBookPageInfos(bookId);
          this.router.navigate(['/' + this.genre.toLowerCase() + '/' + this.currentBookId]);
        } else if(this.bookList.length === 1 && this.bookList[0] === catalog[0]){
          this.bookList.splice(0,1);
        };

        this.bookList = this.bookList.concat(catalog);
        this.setNextBookLinkTitle(0);
        this.setBookmarkButtonTitle(0);
        this.setBuyButtonTitle(0);

        for(var book in this.bookList){
          console.log(book + ': ' + this.bookList[book]);
        }
      }
    });

    if(this.currentBookId){
      this.currentBookmark = this.bookmarkService.getBookmark(this.currentBookId);
      if(this.currentBookmark) {
        this.jumpBookmarkActive = true;
      }
    }

    this.catalogService.loadCatalog(this.genre.toUpperCase(), 0);
  }

  onSwiper(swiper: any) {
    this.swiper = swiper;
    this.initiated = true;
  }

  onSlideChange([swiper]: any) {
    if (this.initiated){
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    if(this.lastIndex < swiper.activeIndex){
      console.log('next');
      this.loadNextBook(swiper.activeIndex);
      this.lastIndex = swiper.activeIndex;
    } else if(this.lastIndex > swiper.activeIndex){
      console.log('history');
      this.loadLastBook(swiper.activeIndex);
    }else {
      this.loadNextBook(this.lastIndex);
    }

      console.log('index:',  swiper.activeIndex);

    }

  }

  get nextBookId(): string{
    let nextBookId = '';
    if(this.bookList.length && this.swiper && this.swiper.activeIndex >= 0){
      const bkid = this.getBookId(this.swiper.activeIndex + 1);
      nextBookId =  bkid ? bkid : '';
    }

    return nextBookId;
  }

  linkNext(event: Event): boolean {
    event.preventDefault();
    event.stopPropagation();
    this.next();
    return false;
  }
  next() {
    if (this.swiper) {
      // Remove query params
      if (this.continueFromHistory) {
        this.continueFromHistory = false;
        this.swiper.slideTo(this.lastIndex, 300);
        return;
      } else {
        this.swiper.slideNext(300);
      }
    }
  }

  last() {
    if (this.swiper) {
      this.showHistory = true;
      setTimeout(()=>{
        this.swiper.slidePrev(200);
      }, 100);

    }
  }

  bookmark() {
    const bookmarkId = this.currentBookId ? this.currentBookId : this.nextBookId;
    if(bookmarkId) {
      console.log('bookmark');
      this.bookmarkService.addBookmark({webId: bookmarkId!!, offset: this.scrollY});
    }
  }

  getBookId(index: number): string | undefined{

      if(this.bookList.length > index){
        const bookListIndex = index % (this.bookList.length);
        return this.bookList[index];
      }
      return;
  }


  loadNextBook(index: number){
    this.index = index;
    // first to history
    let newId = null;
    if(this.currentBookId){
      if(index === this.bookList.length - 1){
        this.catalogService.loadCatalog(this.genre.toUpperCase(), index + 1);
      }
    }

    this.currentBookId = this.getBookId(index);
    if(this.currentBookId){
      this.currentBookmark = this.bookmarkService.getBookmark(this.currentBookId);
      if(this.currentBookmark) {
        this.jumpBookmarkActive = true;
      }
      this.setBookPageInfos(this.currentBookId);
      this.setNextBookLinkTitle(index);
      this.setLastBookLinkTitle(index);
      this.setBookmarkButtonTitle(index);
      this.setBuyButtonTitle(index);
      this.router.navigate(['/' + this.genre + '/' + this.currentBookId ]);
    }
  }

  loadLastBook(index: number){
    if (index >= 0) {
      this.continueFromHistory = true;
      this.currentBookId = this.getBookId(index);
      if(this.currentBookId){
        this.currentBookmark = this.bookmarkService.getBookmark(this.currentBookId);
        if(this.currentBookmark) {
          this.jumpBookmarkActive = true;
        }
        this.setBookPageInfos(this.currentBookId);
        this.setNextBookLinkTitle(index);
        this.setLastBookLinkTitle(index);
        this.setBookmarkButtonTitle(index);
        this.setBuyButtonTitle(index);
        this.router.navigate(['/' + this.genre + '/' + this.currentBookId ]);
      }
      setTimeout(()=>{
        this.showHistory = false;
      }, 100);
    }
  }

  isHistoryDisabled(): boolean {
    return !this.swiper || this.swiper.activeIndex === 0;
  }


  shop(){
    var target = window.open();
    const shopId = this.currentBookId ? this.currentBookId : this.nextBookId;
    if(shopId){
      this.bookService.get(shopId).subscribe((book: Book) => {
        if(book && target){
          target.location = book.amzLink!!;
        }
      });
    }
  }


  showJumpToBookmarkButton(): boolean{
    if(!this.currentBookId){
      return false;
    }

    if(this.isEditor()){
      return false;
    }

    const bookmark = this.bookmarkService.getBookmark(this.currentBookId);
    if(!bookmark) {
      return false;
    }

    return bookmark.offset > 100 && bookmark.offset >= this.scrollY && this.jumpBookmarkActive;
  }

  jumpToBookmark(){
    if(this.currentBookmark){
      this.jumpBookmarkActive = false;
      window.scroll({
        top: (this.currentBookmark.offset - 100),
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  private isEditor(){
    return this.authService.authentication ? this.authService.authentication.sub : false;
  }


  private setGenre(url: string){
    const genres = ['NOVEL', 'CRIME', 'EROTIC', 'HISTORIC', 'HORROR','ROMANCE','MYSTERY','FANTASY','SCIFI','THRILLER'];

    this.genre = 'book';
    const pathNodes = url.split('/');
    if(pathNodes.length && genres.includes(pathNodes[1].toUpperCase())){
        this.genre = pathNodes[1];
    }

  }

  private getGenreDescription(genre: string): string{
      switch (genre){
        case 'NOVEL': return 'ein Roman';
        case 'CRIME': return 'ein Kriminalroman';
        case 'EROTIC': return 'eine erotische Geschichte';
        case 'HISTORIC': return 'eine historische Geschichte';
        case 'HORROR': return 'eine horror Geschichte';
        case 'ROMANCE': return 'eine romantische Geschichte';
        case 'MYSTERY': return 'eine geheimnisvolle Geschichte';
        case 'FANTASY': return 'eine Fantasy Geschichte';
        case 'SCIFI': return 'eine Science-Fiction Geschichte';
        case 'THRILLER': return 'ein Thriller';
        default: return 'ein Buch';
      }

      return 'ein Buch';
  }


  // seo stuff
  private setNextBookLinkTitle(index: number) {

    this.getBookTitleAuthor(index + 1).then(text => {
      if (text) {
        this.nextBookLinkTitle = 'Nächstes Buch: ' + text;
      } else {
        this.nextBookLinkTitle = 'Zum nächsten Buch'
      }
    });
  }
  private setLastBookLinkTitle(index: number) {

    this.getBookTitleAuthor(index - 1).then(text => {
      if (text) {
        this.lastBookLinkTitle = 'Vorheriges Buch: ' + text;
      } else {
        this.lastBookLinkTitle = 'Vorheriges Buch anzeigen'
      }
    });
  }
  private setBookmarkButtonTitle(index: number) {

    this.getBookTitleAuthor(index).then(text => {
      if (text) {
        this.bookmarkLinkTitle = text + ' unter meinen Lesezeichen speichern';
      } else {
        this.bookmarkLinkTitle = 'Für dieses Buch ein Lesezeichen anlegen'
      }
    });
  }
  private setBuyButtonTitle(index: number) {

    this.getBookTitleAuthor(index).then(text => {
      if (text) {
        this.buyLinkTitle = text + ' kaufen';
      } else {
        this.buyLinkTitle = 'Dieses Buch kaufen'
      }
    });
  }


  private async getBookTitleAuthor(index: number): Promise<String> {
    const bookId = this.getBookId(index);
    if(bookId){
      const infos = await this.bookService.getInfo(bookId).toPromise();
      if(infos){
        return infos.title + ' von ' + infos.authors[0].firstName + ' ' + infos.authors[0].lastName;
      }
      return '';
    }

    return '';

  }



  private setBookPageInfos(bookId?: string){
    if(bookId){
      this.bookService.getInfo(bookId).pipe(take(1)).subscribe(info => {
        if(info){
          this.titleService.setTitle(info.title + ' von ' + info.authors[0].firstName + ' ' + info.authors[0].lastName + ' auf chapter.one lesen');
          const pubDate = info.publishingDate ? ' am ' + formatDate(info.publishingDate, 'dd.MM.yyyy', 'de') : '';
          this.metaService.updateTag({
            name: 'description',
            content: info.title + ' ist ' + this.getGenreDescription(info.genre) + ' von ' + info.authors[0].firstName
              + ' ' + info.authors[0].lastName + ', erschienen' + pubDate  + ' im ' + info.publisher + ' Verlag (ISBN: ' +
              info.isbn + ').'}  );

          this.schemaService.updateSchema(info);
        }
      });
    } else{
      this.titleService.setTitle('Love books - match your next!');
    }
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
