import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Bookmark} from "./bookmark.model";



const BOOKMARK_KEY = 'bookmarks';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {

  private $bookmarks = new BehaviorSubject<Bookmark[]>([]);
  private showBookmarks$ = new BehaviorSubject<boolean>(false);
  private animate$ = new BehaviorSubject<boolean>(false);
  constructor() {
    this.initBookmarks();
  }


  getBookmarks(): Observable<Bookmark[]>{
    return this.$bookmarks.asObservable();
  }

  initBookmarks(){
    const bookmarks = localStorage.getItem(BOOKMARK_KEY);
    if(bookmarks){
      const bkmrks = JSON.parse(bookmarks);
      if(bkmrks.length){
        this.$bookmarks.next(bkmrks);
      }
    }
  }

  deleteBookmark(webId: string){
    let bookmarks = this.$bookmarks.getValue();
    const index = this.findBookmarkPosition(webId);
    if(index >= 0){
      bookmarks.splice(index, 1);
      localStorage.setItem(BOOKMARK_KEY, JSON.stringify(bookmarks));
    }
    this.initBookmarks();
  }

  get animate(): Observable<boolean> {
    return this.animate$.asObservable();
  }

  addBookmark(bookmark: Bookmark){
    this.animate$.next(true);
    setTimeout(()=>{
      this.animate$.next(false);
    }, 1000);


    let bookmarks = this.$bookmarks.getValue();
    if(bookmark.webId.length && this.findBookmarkPosition(bookmark.webId) < 0){
      bookmarks.push(bookmark);
      localStorage.setItem(BOOKMARK_KEY, JSON.stringify(bookmarks));
    }
    this.initBookmarks();
  }

  toggleShowBookmarks() {
    this.showBookmarks$.next(!this.showBookmarks$.getValue());
  }


  onShowBookmarks(): Observable<boolean>{
    return this.showBookmarks$.asObservable();
  }

  get count(): number {
    const bookmarks = localStorage.getItem(BOOKMARK_KEY);
    if(bookmarks){
      const bkmrks = JSON.parse(bookmarks);
      return bkmrks.length;
    }
    return 0;
  }

  getBookmark(webId: string): Bookmark | null {
    const index = this.findBookmarkPosition(webId);
    if(!(index < 0 )){
      return this.$bookmarks.getValue()[index];
    }

    return null;
  }

  hasBookmark(webId: string): boolean {
    return !(this.findBookmarkPosition(webId) < 0);
  }

  private findBookmarkPosition(webId: string): number {
    let bookmarks = this.$bookmarks.getValue();
    for(let i = 0; i < bookmarks.length; i++){
      if(bookmarks[i].webId === webId) {
        return i;
      }
    }

    return -1;
  }

  updateBookmark(webId: string, options: any){
    const bookmarks = this.$bookmarks.getValue();
    bookmarks.forEach(bookmark => {
      if(bookmark.webId === webId){
        bookmark.offset = options.offset;
      }
    });
    localStorage.setItem(BOOKMARK_KEY, JSON.stringify(bookmarks));
    this.initBookmarks();
  }

}
